const set = (key, data) => {
  const dataToStorage = JSON.stringify(data);

  localStorage.setItem(key, dataToStorage);
};

const get = (key) => {
  const data = localStorage.getItem(key);

  return JSON.parse(data);
};

const remove = (key) => localStorage.removeItem(key);

export const LocalStorage = {
  set,
  get,
  remove,
};

export default LocalStorage;
