import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "./App.css";
import { getFormatHost } from "@appypay/common/utils/format";
import { productionHosts } from "@appypay/common/utils/constants";
import LocalStorage from "@appypay/common/utils/localStorage";
import { mixPanelInit } from "@appypay/common/utils/mixpanelEvent";
import * as Sentry from "@sentry/react";
import i18n from "localization";
import { Helmet } from "react-helmet";
import authentication from "./auth";
import App from "./App";
import { getAggregatorImage } from "@appypay/common/utils/ImageSrcHelper";

(async () => {
  const authConfig = await (
    await fetch(`${process.env.PUBLIC_URL}/assets/config.json`)
  ).json();

  Sentry.init({
    dsn: "https://94f342786bf246cd9a02d7ba83f94fab@o1315435.ingest.sentry.io/4505425432281088",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  });

  window.appyConfig = {
    ...authConfig,
    AGGREGATOR: authConfig?.AGGREGATOR || "appy",
  };

  const isProduction =
    getFormatHost() === productionHosts.landing ||
    getFormatHost() === productionHosts.portal;

  const isLanding =
    getFormatHost() === productionHosts.landing ||
    window.location.pathname === "/";

  const logOutUrl = () => {
    if (isProduction && isLanding) {
      return `${window.location.protocol}//${productionHosts.portal}`;
    }

    if (isProduction && !isLanding) {
      return `${window.location.protocol}//${productionHosts.landing}`;
    }

    if (isLanding) {
      return `${window.location.origin}/portal`;
    }

    return window.location.origin;
  };

  authentication.initialize({
    instance: authConfig.ADB2C_INSTANCE,
    tenant: authConfig.ADB2C_TENANT,
    signInPolicy:
      LocalStorage.get("APPY_B2C_POLICY") || authConfig.ADB2C_SIGNIN_POLICY,
    applicationId: authConfig.ADB2C_APPLICATION_ID,
    cacheLocation: authConfig.ADB2C_CACHE_LOCATION,
    scopes: authConfig.ADB2C_SCOPES.split(","),
    resetPolicy: authConfig.ADB2C_RESET_POLICY,
    redirectUri: window.location.origin,
    validateAuthority: false,
    postLogoutRedirectUri: logOutUrl(),
    silentLoginOnly: false,
    language: LocalStorage.get("lang") || i18n.language,
  });

  mixPanelInit(authConfig.MIXPANEL_KEY);

  ReactDOM.render(
    // TODO: return StrictMode when project will done
    <>
      <Helmet>
        <script>document.documentElement.style.setProperty('--primary', window.appyConfig.MERCHANT_PRIMARY_COLOR);</script>
        <link
          href={getAggregatorImage(window.appyConfig.MERCHANT_LOGO_FAV)}
          id="favicon"
          rel="icon"
        />
        <meta
          class="preview-icon"
          content={getAggregatorImage(window.appyConfig.MERCHANT_LOGO)}
          property="og:image"
        />
        <meta
          class="preview-icon"
          content={getAggregatorImage(window.appyConfig.MERCHANT_LOGO)}
          property="og:image:url"
        />
        <meta
          class="preview-icon"
          content={getAggregatorImage(window.appyConfig.MERCHANT_LOGO)}
          property="og:image:secure_url"
        />
        <meta
          class="preview-icon"
          content={getAggregatorImage(window.appyConfig.MERCHANT_LOGO)}
          property="twitter:image"
        />
        <meta
          class="preview-icon"
          content={getAggregatorImage(window.appyConfig.MERCHANT_LOGO)}
          property="msapplication-TileImage"
        />
        <meta
          content={window.appyConfig.MERCHANT_COMPANY_NAME}
          property="og:title"
        />
        <meta content={window.appyConfig.MERCHANT_URL} property="og:url" />
        <meta
          content={window.appyConfig.MERCHANT_DESCRIPTION}
          property="og:description"
        />
        <meta
          content={window.appyConfig.MERCHANT_DESCRIPTION}
          name="description"
        />
        <link
          href={getAggregatorImage(window.appyConfig.MERCHANT_LOGO)}
          rel="apple-touch-icon"
        />
        <title>{window.appyConfig.MERCHANT_COMPANY_NAME}</title>
        {window.appyConfig.AGGREGATOR === "appy" && (
          <script
            defer
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=268699c8-458f-4147-b888-6ff05a3dae64"
          ></script>
        )}
      </Helmet>
      <App />
    </>,
    document.getElementById("root")
  );
})();
