import { format } from "date-fns";

import getSymbolOfCurrency from "./currency";

export const getMessageFromError = (error) => {
  if (error.response && error.response.data) {
    if (error.response.data.errors) {
      const errorsList = [];
      Object.keys(error.response.data.errors).forEach((err) => {
        errorsList.push(error.response.data.errors[err][0]);
      });

      return errorsList;
    }
    return error.response.data.message;
  }
  return error.message;
};

export const getFormattedPercent = (num = 0, fixed = 1) =>
  Number(num).toFixed(fixed).toString().replace(".", ",").replace(/,0$/, "");

export const getFormattedDate = (stringDate, formatString = "dd/MM/yyyy") =>
  format(new Date(stringDate), formatString);

export const getFormattedTime = (stringDate, formatString = "HH:mm") =>
  format(new Date(stringDate), formatString);

export const getShortNumberFormat = (number) =>
  Math.abs(number) > 999 ? `${(Math.abs(number) / 1000).toFixed(1)} K` : number;

export const getFormattedAmount = (amount, int, currency = "AOA") => {
  const formatter = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: int ? 0 : 2,
    maximumFractionDigits: int ? 0 : 2,
  });

  return `${formatter.format(amount)} ${getSymbolOfCurrency(currency)}`;
};

export const formatAmountForBack = (amount = 0) => {
  if (typeof amount === "number") return amount;

  return parseFloat(
    amount.replace(/\./g, "").replace(/,/g, ".").replace(/ Kz/g, "")
  );
};
export const nFormatter = (num, digits) => {
  const si = { value: 1e6, symbol: "M" };
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  const formatter = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const amount = (num / si.value).toFixed(digits).replace(rx, "$1");
  return `${formatter.format(amount)}${si.symbol}`;
};

export const getFormatHost = () => {
  const { host } = window.location;

  return host.replace("www.", "");
};

export const formatIBAN = (value = "") => value?.match(/.{1,4}/g).join(" ");
export const maskString = (value = "", length = 6, starLength = 10) => {
  let star = "";
  for (let i = 0; i < starLength; i++) {
    star = `${star}*`;
  }
  return `${value.slice(0, length)}${star}`;
};
