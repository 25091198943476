import React from 'react';
import { getFormatHost } from '@appypay/common/utils/format';
import { productionHosts } from '@appypay/common/utils/constants';
import MainLoader from '@appypay/common/ui/MainLoader';

const DevRouters = React.lazy(() => import('./DevRouters'));
const ProdRouters = React.lazy(() => import('./ProdRouters'));

function Routes() {
  const isProduction =
    getFormatHost() === productionHosts.portal ||
    getFormatHost() === productionHosts.landing;

  return (
    <React.Suspense fallback={<MainLoader size={300} />}>
      {isProduction ? <ProdRouters /> : <DevRouters />}
    </React.Suspense>
  );
}

export default React.memo(Routes);
