import mixpanel from 'mixpanel-browser';

export const mixPanelInit = (token) => {
  try {
    mixpanel.init(token);
  } catch (e) {
    /**/
  }
};

export const mixPanelInitUser = (userInfo) => {
  try {
    mixpanel.people.set({
      $email: userInfo.email,
      $name: userInfo.name,
      USER_ID: userInfo.id,
    });
    mixpanel.identify(userInfo.id);
  } catch (e) {
    /**/
  }
};

export const sendMixpanelEvent = ({ name = '', event = '', value = {} }) => {
  const eventName = name.toLowerCase().replace(/\s/g, '_');

  const location = window.location.pathname;

  try {
    mixpanel.track(
      `${eventName} ${event} ${location ? `on ${location}` : ''}`,
      {
        ...value,
      }
    );
  } catch (e) {
    /**/
  }
};
